import { h } from 'preact'
import classNames from 'clsx'

import { getDisplayName } from '../../utils'

import styles from './index.module.scss'

export const FONT_ROBOTO_CONDENSED = 'roboto-condensed'

const withFont = (Component, font, displayName) => {
  const WrappedComponent = ({ className, ...props }) => (
    <Component
      {...props}
      className={buildFontClassName({
        className,
        font,
      })}
    />
  )

  WrappedComponent.displayName = displayName || `withFont(${getDisplayName(Component)})`

  return WrappedComponent
}

export function buildFontClassName({ className, font }) {
  return classNames(
    className,
    styles[`u-font-${font}`],
  )
}

export default withFont
